<template>
  <header class="header">Exhibition list</header>
  <div class="cc-list-content">
    <a-table
      class="table"
      bordered
      :columns="columns"
      :data-source="exhibitionTaskList"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'action'">
          <a
            href="#"
            @click="
              copy(
                `${record.exhibitionUrl}?inviteCode=${userData.id}&sourceCode=CallCenter&l=${record.id}`
              )
            "
            >Copy ExhibitionUrl</a
          >
        </template>
        <template v-if="column.key === 'exhibitionUrl'">
          <div>
            {{
              `${record.exhibitionUrl}?inviteCode=${userData.id}&sourceCode=CallCenter&l=${record.id}`
            }}
          </div>
        </template>
      </template>
    </a-table>
  </div>
</template>
<script>
import { message } from 'ant-design-vue'
import { reactive, toRefs } from 'vue'
import { setTheLocalStore } from '@/utils/setTheLocalStore'
const columns = [
  {
    title: 'Title',
    dataIndex: 'exhibitionNameEn',
    key: 'exhibitionNameEn',
  },
  {
    title: 'Sub Title',
    dataIndex: 'subtitleEn',
    key: 'subtitleEn',
  },
  {
    title: 'Start Time',
    dataIndex: 'startTime',
    key: 'startTime',
  },
  {
    title: 'End Time',
    dataIndex: 'endTime',
    key: 'endTime',
  },
  {
    title: 'Exhibition Url',
    key: 'exhibitionUrl',
  },
  {
    title: 'Operate',
    key: 'action',
    align: 'center',
  },
]
import { getExhibitionTaskLists } from '@/api/customer'
export default {
  setup() {
    const data = reactive({
      exhibitionTaskList: [],
      columns,
      userData: null,
    })
    data.userData =
      setTheLocalStore.getLocalStore('userData', 'localStorage') || {}
    exhibitionTaskLists()
    //获取所有展会任务
    function exhibitionTaskLists() {
      getExhibitionTaskLists().then((res) => {
        data.exhibitionTaskList = res.data
      })
    }
    function copy(link) {
      var input = document.createElement('input') // 直接构建input
      input.value = link // 设置内容
      document.body.appendChild(input) // 添加临时实例
      input.select() // 选择实例内容
      document.execCommand('Copy') // 执行复制
      document.body.removeChild(input) // 删除临时实例
      message.success('Copy successful')
    }
    return {
      ...toRefs(data),
      copy,
      exhibitionTaskLists,
    }
  },
}
</script>

<style lang="less" scoped>
@import '../../assets/cc-list-tap-container.less';
@import '../../assets/cc-search-wrap.less';
.linkCopy {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
  padding: 12px;
  border-bottom: 1px solid #e8e8e8;
  font-size: 14px;
  .link {
    color: rgba(0, 0, 0, 0.65);
    line-height: 22px;
  }
}
.btn {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
.table {
  margin-top: 50px;
}
.header {
  background: #fff;
  font-size: 16px;
  line-height: 48px;
  padding: 0px 15px;
  // border-radius: 5px;
}
</style>
